/* eslint-disable no-param-reassign */
import logger from 'loglevel';
import EBAConfig from '../EBAConfig/EBAConfig';

/*  This module provides a workaround for cleaning up eventhandlers added on
    document/window/body by the Snowplow SDK.
    This is a hard requirement for the TV apps using the Lightning framework,
    otherwise they don't pass certification by the STB providers.
    The Snowplow SDK does not offer any functions to clean up the handlers, nor does it expose the handlers.
    Hence we capture them during module load, by temporarily overriding the addEventHandlers
    of document/window/body.
    This happens at 2 times:
    - during Snowplow SDK module load (cfr import statement in SnowplowSubscriber)
    - during Snowplow tracker instantiation (cfr install method in SnowplowSubscriber)
    Finally, the uninstall method of VRT.EBA is rigged to call the cleanUp function of this module,
    which will effectively remove the captured eventhandlers from document/window/body */

function SnowplowListenerCleanerFactory() {
    let patched = false;

    const handlerConfigs = [
        {
            eventTarget: window,
            originalAEL: null,
            capturedHandlers: [],
        },
        {
            eventTarget: document,
            originalAEL: null,
            capturedHandlers: [],
        },
        {
            eventTarget: document.body,
            originalAEL: null,
            capturedHandlers: [],
        },
    ];

    // =========
    // privates
    // =========

    function proxyAEL(handlerConfig) {
        return function proxy(type, listener, options) {
            logger.debug(`EBA SnowplowListenerCleaner: captured ${type} eventhandler`);
            handlerConfig.capturedHandlers.push({ type, listener, options });
            handlerConfig.originalAEL(type, listener, options);
        };
    }

    const patch = () => {
        if (!EBAConfig.snowplow_aggressiveCleanup) return;
        try {
            if (!patched) {
                logger.debug('EBA SnowplowListenerCleaner: patching');
                handlerConfigs.forEach((handlerConfig) => {
                    handlerConfig.originalAEL = handlerConfig.eventTarget.addEventListener.bind(handlerConfig.eventTarget);
                    handlerConfig.eventTarget.addEventListener = proxyAEL(handlerConfig);
                });
                patched = true;
            }
        } catch (e) {
            logger.warn('EBA SnowplowListenerCleaner: failed to patch addEventListeners');
        }
    };

    // =======
    // publics
    // =======

    const unpatch = () => {
        if (!EBAConfig.snowplow_aggressiveCleanup) return;
        try {
            if (patched) {
                logger.debug('EBA SnowplowListenerCleaner: unpatching');
                handlerConfigs.forEach((handlerConfig) => {
                    handlerConfig.eventTarget.addEventListener = handlerConfig.originalAEL;
                });
                patched = false;
            }
        } catch (e) {
            logger.warn('EBA SnowplowListenerCleaner: failed to unpatch addEventListeners');
        }
    };

    const cleanUp = () => {
        if (!EBAConfig.snowplow_aggressiveCleanup) return;
        try {
            logger.debug('SnowplowListenerCleaner: cleanUp');
            handlerConfigs.forEach((handlerConfig) => {
                handlerConfig.capturedHandlers.forEach((handler) => {
                    logger.debug(`EBA SnowplowListenerCleaner: removing ${handler.type} eventhandler`);
                    handlerConfig.eventTarget.removeEventListener(handler.type, handler.listener, handler.options);
                });
            });
        } catch (e) {
            logger.warn('EBA SnowplowListenerCleaner: failed to cleanUp');
        }
    };

    patch(); // make sure to activate at import time already, next imports of Snowplow npm packages already add event listeners

    return {
        patch,
        unpatch,
        cleanUp,
    };
}

export default SnowplowListenerCleanerFactory();
