import logger from 'loglevel';
// eslint-disable-next-line import/no-relative-packages
import EBAConfig from './EBAConfig/EBAConfig';
import EDDLPublisher from './EDDL/EDDLPublisher';
import CEDDLSubscriber from './CEDDL/CEDDLSubscriber';
import SnowplowSubscriber from './Snowplow/SnowplowSubscriber';
import GemiusSubscriber from './Gemius/GemiusSubscriber';

// upgrade digitalData.events array to pub-sub mechanism
window.digitalData = window.digitalData || {};
window.digitalData.pastEvents = window.digitalData.events || [];
EDDLPublisher.install(window.digitalData.pastEvents);
delete window.digitalData.pastEvents;

// install and expose the necessary subscribers
window.VRT = window.VRT || {};
window.VRT.EBA = window.VRT.EBA || {};

if (EBAConfig.ceddl_enabled) {
    CEDDLSubscriber.install();
    window.VRT.EBA.CEDDLSubscriber = CEDDLSubscriber;
}

if (EBAConfig.snowplow_enabled) {
    SnowplowSubscriber.install();
    window.VRT.EBA.SnowplowSubscriber = SnowplowSubscriber;
}

if (EBAConfig.gemius_enabled) {
    GemiusSubscriber.install();
    window.VRT.EBA.GemiusSubscriber = GemiusSubscriber;
}

// expose publisher and logger
window.VRT.EBA.EDDLPublisher = EDDLPublisher;
window.VRT.EBA.logger = logger;
window.VRT.EBA.startRemoteDebugging = EDDLPublisher.startRemoteDebugging;
window.VRT.EBA.stopRemoteDebugging = EDDLPublisher.stopRemoteDebugging;

// offer uninstall
window.VRT.EBA.uninstall = () => {
    try {
        EDDLPublisher.removeSubscribers();
        if (EBAConfig.ceddl_enabled) CEDDLSubscriber.uninstall();
        if (EBAConfig.snowplow_enabled) SnowplowSubscriber.uninstall();
        if (EBAConfig.gemius_enabled) GemiusSubscriber.uninstall();
        EDDLPublisher.uninstall();
        delete window.EBA;
    } catch (e) {
        logger.warn('EBA: failed to uninstall');
        logger.warn(e);
    }
};

setTimeout(() => { window.dispatchEvent(new CustomEvent('EBAReady2')); }, 100);
