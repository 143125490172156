import logger from 'loglevel';
import SnowplowListenerCleaner from './SnowplowListenerCleaner'; // ATTN: do not move this below @snowplow/browser-tracker !
// eslint-disable-next-line import/order
import {
    newTracker,
    trackSelfDescribingEvent,
    flushBuffer,
    clearUserData,
    clearGlobalContexts,
} from '@snowplow/browser-tracker';
// eslint-disable-next-line import/no-relative-packages
import EBAConfig from '../EBAConfig/EBAConfig';

// remove the monkey patches on addEventListener, first set of listeners (due to @snowplow/browser-tracker importing) should have been captured
SnowplowListenerCleaner.unpatch();

const appContextsToSkip = [
    'iglu:be.vrt/context_consent/',
    'iglu:be.vrt/context_user/',
    'iglu:be.vrt/context_page/',
    'iglu:be.vrt/context_platform/',
    'iglu:be.vrt/context_device/',
    'iglu:be.vrt/context_browser/',
];

function SnowplowSubscriberFactory() {
    let timeout = null;
    let installed = false;
    let deliveryFunction = null;

    const stats = {
        eventsProcessed: 0,
        eventsDelivered: 0,
        eventsFailed: 0,
    };

    // =========
    // privates
    // =========

    const flushEventsToSnowplow = () => {
        flushBuffer();
    };

    const shouldPushEvent = (ev) => !(EBAConfig.in_app && EBAConfig.snowplow_appSkipList.find((skipSchemaName) => ev.event.schema.startsWith(skipSchemaName)));

    const pushEventToSnowplow = (ev) => {
        logger.debug('EBA: pushEventToSnowplow');
        logger.debug(JSON.stringify(ev));
        stats.eventsProcessed += 1;
        if (shouldPushEvent(ev)) {
            clearTimeout(timeout);
            try {
                deliveryFunction(ev);
                stats.eventsDelivered += 1;
            } catch (e) {
                stats.eventsFailed += 1;
                logger.warn('EBA: snowplow delivery failure');
            }
            timeout = setTimeout(flushEventsToSnowplow, EBAConfig.snowplow_flushTimeout);
        } else {
            logger.debug('EBA: should not push event to Snowplow');
        }
    };

    const deliverToApp = (ev) => {
        const appEvent = {
            event: ev.event,
            context: ev.context.filter((context) => !appContextsToSkip.find((skipSchemaName) => context.schema.startsWith(skipSchemaName))),
        };
        window.EBAMobile.postMessage(JSON.stringify(appEvent));
    };

    // =========
    // publics
    // =========

    const install = () => {
        try {
            if (EBAConfig.snowplow_enabled && !installed) {
                if (EBAConfig.in_app && window.EBAMobile?.postMessage) {
                    deliveryFunction = deliverToApp;
                } else {
                    SnowplowListenerCleaner.patch(); // capture new eventhandlers added by Snowplow
                    newTracker('sp1', EBAConfig.snowplow_collectorUrl, {
                        postPath: '/be.vrt/t',
                        appId: EBAConfig.snowplow_appid,
                        eventMethod: EBAConfig.snowplow_eventMethod,
                        bufferSize: EBAConfig.snowplow_bufferSize,
                        cookieSecure: EBAConfig.snowplow_cookieSecure,
                        cookieDomain: EBAConfig.snowplow_cookieDomain,
                        cookieSameSite: 'Lax',
                        discoverRootDomain: EBAConfig.snowplow_discoverRootDomain,
                        encodeBase64: EBAConfig.snowplow_encodeBase64,
                        maxLocalStorageQueueSize: EBAConfig.snowplow_maxLocalStorageQueueSize,
                        platform: 'web',
                        plugins: [],
                        respectDoNotTrack: false,
                        stateStorageStrategy: 'localStorage',
                    });
                    deliveryFunction = trackSelfDescribingEvent;
                    SnowplowListenerCleaner.unpatch();
                }
                window.digitalData.events.push({ subscribe: pushEventToSnowplow });
                installed = true;
            }
        } catch (e) {
            logger.warn('EBA: failed to install SnowplowSubscriber');
        }
    };

    const uninstall = () => {
        try {
            logger.debug('EBA SnowplowSubscriber: uninstalling');
            SnowplowListenerCleaner.cleanUp();
            clearUserData({ preserveSession: false, preserveUser: false }, ['sp1']);
            clearGlobalContexts(['sp1']);
        } catch (e) {
            logger.warn('EBA: exception while uninstalling SnowplowSubscriber');
        }
    };

    return {
        install,
        uninstall,
        stats,
    };
}

export default SnowplowSubscriberFactory();
