import logger from 'loglevel';

function SSOHelperFactory() {
    let marketingid = null;
    let profiletype = null;

    let subscribedToSSO = false;

    const isSSOReady = () => {
        try {
            logger.debug(`EBA SSOHelper: SSOController ${typeof window.VRT.SSOController}`);
            if (!window.VRT.SSOController) return false;
            logger.debug(`EBA SSOHelper: SSOController.getState() ${window.VRT.SSOController.getState()}`);
            return (window.VRT.SSOController.getState() !== 0);
        } catch (e) {
            logger.warn('EBA SSOHelper: exception in isSSOReady()');
            return false;
        }
    };

    const querySSOController = () => {
        marketingid = window.VRT.SSOController.getMarketingId();
        if (marketingid) {
            profiletype = window.VRT.SSOController.getProfile() ? 'child' : 'main';
        } else {
            profiletype = null;
        }
    };

    const asyncGetMarketingID = (ev) => {
        logger.debug(`EBA SSOHelper : got called back by SSOController with ${ev}`);
        // don't trust ev, it does not contain the right marketingid for subprofiles
        querySSOController();
        logger.debug(`EBA SSOHelper : marketingid is now ${marketingid} and profiletype is ${profiletype}`);
    };

    const syncGetMarketingID = () => {
        try {
            if (isSSOReady()) {
                querySSOController();
                // 2. subscribe to SSO for further async updates, if not the case already
                if (!subscribedToSSO) {
                    window.VRT.SSOController.addEventListener('SSO-stateChanged', asyncGetMarketingID);
                    subscribedToSSO = true;
                }
            }
            return marketingid;
        } catch (e) {
            logger.warn('EBA SSOHelper: exception in syncGetMarketingID()');
            return false;
        }
    };

    const getMarketingID = () => {
        if (!subscribedToSSO) syncGetMarketingID();
        return marketingid;
    };

    const getProfileType = () => {
        if (!subscribedToSSO) syncGetMarketingID();
        return profiletype;
    };

    return {
        isSSOReady,
        getMarketingID,
        getProfileType,
    };
}

export default SSOHelperFactory();
